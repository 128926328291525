import Vue from 'vue'
import { reqUrl as postUrl } from '@/config'

export const xPost = (requestClass, requestFunction, params = {}) => {
  return Vue.axios({
    method: 'post',
    url: postUrl + '/' + requestClass + '/' + requestFunction,
    data: {
      params: JSON.stringify({
        params
      })
    },
    transformRequest: [data => {
      // Do whatever you want to transform the data
      let ret = ''
      for (const it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret
    }],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    withCredentials: true
  })
}

export const xDownload = async (fileFullName, downloadAsName, otherParams = {}) => {
  try {
    const res = await xPost(
      'file',
      'downtok',
      otherParams
    )
    let downTok = ''
    if (res.data.status === 200) {
      downTok = res.data.data
    }
    const myparams = {
      params: {
        file: fileFullName,
        download: true,   // download or not
        downFilename: downloadAsName,
        downTok,
      }
    }
    const myparamsStringify = JSON.stringify(myparams)

    const url = postUrl + '/file/download?params=' + encodeURIComponent(myparamsStringify)
    const elink = document.createElement('a')
    elink.download = downloadAsName
    elink.style.display = 'none'
    elink.href = url
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)

    return true
    // return instance
  } catch (err) {
    console.log('Error - download')
    console.log(err)
    // return false
  }

}
  
// export const xDownload = async (fileFullName, downloadAsName, otherParams = {}) => {
//   try {
//     const myparams = {
//       params: {
//         file: fileFullName,
//         download: true,   // download or not
//         // downFilename: downloadAsName,
//         ...otherParams    // if necessary
//       }
//     }
//     const myparamsStringify = JSON.stringify(myparams)

//     const axios = Vue.axios({
//       method: 'post',
//       url: postUrl + '/file/download',
//       responseType: 'blob',
//       data: {
//         params: myparamsStringify
//       },
//       transformRequest: [data => {
//         // Do whatever you want to transform the data
//         let ret = ''
//         for (const it in data) {
//           ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//         }
//         return ret
//       }],
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded'
//       }
//     })
//     const res = await axios
//     const content = res.data    
//     const blob = new Blob([content])
//     const fileName = downloadAsName
//     let wURL = window.URL || window.webkitURL || window.mozURL
//     if ('download' in document.createElement('a')) { // 非IE下载
//       const elink = document.createElement('a')
//       elink.download = fileName
//       elink.style.display = 'none'
//       elink.href = wURL.createObjectURL(blob)
//       elink.target = "_blank"
//       document.body.appendChild(elink)
//       elink.click()
//       wURL.revokeObjectURL(elink.href) // 释放URL 对象
//       document.body.removeChild(elink)
//     } else { // IE10+下载
//       navigator.msSaveBlob(blob, fileName)
//     }

//     return true
//     // return instance
//   } catch (err) {
//     console.log('Error - download')
//     // console.log(err)
//     // return false
//   }
// }